@media (max-width: 991.98px) {
  .show {
    height: calc(100vh - 56px);
  }
  .navbar {
    align-items: start;
  }
  div.navbar-nav {
    width: 75%;
  }
  div.navbar-nav > .nav-link {
    font-size: 2.5rem;
  }
  /* .navbar.navbar-expand-lg.navbar-dark.bg-transparent.fixed-top {
    background-color: greenyellow !important;
  } */
}

.h1-header {
  /* display: none; */
  position: absolute;
  z-index: -1000;
  opacity: 0;
}

.carousel-item {
  height: 100vh;
  min-height: 350px;
  background: no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

/* .c-a {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
} */
.cta.btn-outline-warning {
  text-shadow: -1px 1px 1px #000;
}
.cta.btn-outline-warning:hover {
  text-shadow: none;
}

.cta {
  position: absolute;
  top: 50%;
  left: 50%;
  border-width: 1.5px;
  transform: translate(-50%, -50%);
  animation: transitionIn 2s;
}

@keyframes transitionIn {
  from {
    opacity: 0;
    top: 40%;
    border-width: 1px;
    transform: translate(-50%, -60%);
  }
  to {
    opacity: 1;
    top: 50%;
    border-width: 1.5px;
    transform: translate(-50%, -50%);
  }
}

.main {
  margin-top: 62px;
  min-height: calc(100vh - 132.67px);
  padding: 0;
}

@media (max-width: 991.98px) {
  .main {
    margin-top: 56px;
  }
}

.main h2 {
  /* margin-top: 5em; */
}

.height14 {
  min-height: 25vh;
}
.height12 {
  min-height: 50vh;
}
.height34 {
  min-height: 75vh;
}

.about {
  width: 50vw;
}

.bg-dotted {
  background-image: radial-gradient(#d7d7d7 1px, transparent 1px),
    radial-gradient(#ff9935 1px, transparent 1px);
  background-position: 0 0, 10px 10px;
  background-size: 20px 20px;
}

@media (min-width: 768px) {
  .lbd {
    border-left: 0.5px dotted #c0c0c0;
    padding-left: 15px;
  }
}

.serve-item {
  border: 1px solid #d4d4d4;
  border-radius: 5px;
  height: 2em;
  width: 100%;
  text-align: center;
}

@media (max-width: 767.98px) {
  .serve-item {
    border: 1px solid #ff9935;
  }
}

.serve-item:hover {
  border: 1px solid #ff9935;
}

.service-item {
  background-color: #f8f9fa;
  height: 100%;
  padding: 0.5rem;
}

@media (max-width: 767.98px) {
  .branch {
    text-align: center;
  }
}

a.gss_l {
  color: greenyellow;
}

/* contact */
.modal-success div {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.modal-error div {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

ul.phone-list {
  list-style: none;
}

@media (max-width: 767.98px) {
  .contact-widget {
    text-align: center;
  }
  ul.phone-list {
    margin-top: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  ul.phone-list {
    list-style: none;
    margin-top: -2rem;
    padding-left: 3.5rem;
  }
  .social-lnks {
    margin-top: -1.8rem;
    padding-left: 3.2rem;
  }

}

.social-lnks a {
  padding: 0.4em;
}
.social-lnks a svg{
  height: 20px;
  width: 20px;
}

.social-links a {
  color: #f8f9fa;
  margin: auto 0.5em;
  text-decoration: none;
}

.social-links a:hover {
  color: rgba(255,255,255,0.55);
  cursor: pointer;
}

.ml-auto {
  margin-left: auto;
}